<script>
import CustomerInfo from '~/javascripts/profile/components/customer_info.vue';

export default {
  name: 'SignUpApp',
  components: {
    CustomerInfo,
  },
  props: {
    registerPath: {
      type: String,
      required: true,
    },
    accountInfo: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
};
</script>

<template>
  <div class="gl-mt-7">
    <div class="gl-mx-auto sm:gl-w-full lg:gl-w-1/2">
      <h2 class="gl-text-center gl-font-bold">Sign in</h2>
      <p class="gl-mt-6 gl-text-center gl-font-bold">You're almost there!</p>
      <p class="gl-mb-6 gl-mt-3 gl-text-center">
        We need a little more information from you before you can continue to the
        <span class="gl-whitespace-nowrap">Customers Portal</span>.
      </p>
      <customer-info
        class="gl-mb-10 gl-mt-7"
        :register-path="registerPath"
        :account-info="accountInfo"
      />
    </div>
  </div>
</template>
