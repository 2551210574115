<script>
import { GlFormSelect, GlFormGroup } from '@gitlab/ui';
import { ValidationProvider } from 'vee-validate';
import GET_COUNTRY_LIST_QUERY from '~/graphql/queries/get_country_list.query.graphql';
import validationStateMixin from '~/javascripts/forms/validation/mixins/validation_state_mixin';

export default {
  name: 'CountrySelect',
  components: { GlFormSelect, GlFormGroup, ValidationProvider },
  mixins: [validationStateMixin],
  props: {
    isEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: true,
    },
    initialCountry: {
      type: String,
      required: false,
      default: '',
    },
    cssClasses: {
      type: Array,
      required: false,
      default: () => [],
    },
    useNameForValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: 'Country',
    },
  },
  data() {
    return {
      countries: [],
      selectedCountry: this.initialCountry,
    };
  },
  computed: {
    countryOptions() {
      if (this.useNameForValue) {
        return this.countries.map((country) => {
          return {
            text: country.text,
            value: country.text,
          };
        });
      }

      return this.countries;
    },
  },
  methods: {
    setCountry(country) {
      this.selectedCountry = country;
      this.$emit('change', this.selectedCountry);
    },
  },
  apollo: {
    countries: {
      query: GET_COUNTRY_LIST_QUERY,
    },
  },
};
</script>

<template>
  <validation-provider #default="validationContext" name="Country" rules="required" slim>
    <gl-form-group
      :class="cssClasses"
      :label="label"
      label-for="country"
      :invalid-feedback="validationContext.errors[0]"
      :state="getValidationState(validationContext)"
    >
      <gl-form-select
        id="country"
        v-model="selectedCountry"
        :options="countryOptions"
        :disabled="!isEnabled"
        v-bind="$attrs"
        :state="getValidationState(validationContext)"
        @change="setCountry"
      >
        <template #first>
          <option value disabled selected>Select country</option>
        </template>
      </gl-form-select>
    </gl-form-group>
  </validation-provider>
</template>
