import { init, browserTracingIntegration } from '@sentry/browser';

const initSentry = () => {
  const ignoreErrors = [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
  ];

  const denyUrls = [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    // OneTrust scripts
    /cdn\.cookielaw\.org/i,
  ];

  if (['development', 'test'].includes(import.meta.env.MODE)) return;

  init({
    dsn: gon.global.sentryDsn,
    release: gon.global.version,
    environment: import.meta.env.MODE,
    autoSessionTracking: true,

    // Browser tracing configuration
    tracePropagationTargets: [/^\//], // only trace internal requests
    // Capture 50% of transactions for performance monitoring.
    tracesSampleRate: 0.5,
    denyUrls,
    ignoreErrors,
    integrations: [browserTracingIntegration()],
    initialScope(scope) {
      if (gon?.user) {
        scope.setUser({
          id: gon.user.id,
          email: gon.user.email,
          company: gon.user.company,
        });
      }

      return scope;
    },
  });
};

initSentry();
