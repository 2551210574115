<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { GlButton, GlFormGroup, GlFormInput, GlFormSelect } from '@gitlab/ui';
import validationStateMixin from '~/javascripts/forms/validation/mixins/validation_state_mixin';
import { EMPLOYEES_COUNT } from '~/javascripts/sessions/sessions_constants';
import CountrySelect from '~/javascripts/components/country_select.vue';
import StateSelect from '~/javascripts/components/state_select.vue';
import csrf from '~/javascripts/lib/csrf';

export default {
  name: 'CustomerInfo',
  EMPLOYEES_COUNT,
  components: {
    GlButton,
    GlFormGroup,
    GlFormInput,
    GlFormSelect,
    CountrySelect,
    StateSelect,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [validationStateMixin],
  inject: {
    countriesToHideVatId: {
      default: [],
    },
  },
  props: {
    registerPath: {
      type: String,
      required: true,
    },
    accountInfo: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      // ValidationProvider requires inputs to be v-model'd
      form: {
        firstName: null,
        lastName: null,
        organization: null,
        employeeCount: null,
        address1: null,
        address2: null,
        city: null,
        zipCode: null,
        vatCode: null,
      },
      selectedCountry: '',
      csrfToken: csrf.token,
    };
  },
  computed: {
    vatFieldVisible() {
      if (!this.selectedCountry) {
        return false;
      }
      return !this.countriesToHideVatId.includes(this.selectedCountry);
    },
    customerName() {
      const fullName = this.accountInfo?.name;
      if (!fullName) return undefined;

      const [firstName, ...restOfName] = fullName.trim().split(' ');
      const [lastName] = restOfName.slice(-1);

      return {
        firstName,
        lastName,
      };
    },
  },
  created() {
    this.initializeForm();
  },
  methods: {
    initializeForm() {
      this.form.firstName = this.customerName?.firstName;
      this.form.lastName = this.customerName?.lastName;
      this.form.organization = this.accountInfo?.organization;
    },
    countryChanged(country) {
      this.selectedCountry = country;
    },
    onSubmit(event, handleSubmit) {
      handleSubmit(() => event.target.submit());
    },
  },
};
</script>

<template>
  <validation-observer #default="{ handleSubmit }" slim>
    <form method="post" :action="registerPath" @submit.prevent="onSubmit($event, handleSubmit)">
      <input name="utf8" type="hidden" value="✓" />
      <input type="hidden" name="authenticity_token" :value="csrfToken" />

      <div class="row">
        <div class="col-md-6 col-12">
          <validation-provider
            #default="validationContext"
            rules="required|noEmoji"
            name="First name"
            data-testid="first-name"
            slim
          >
            <gl-form-group
              label="First name"
              label-for="first_name"
              :invalid-feedback="validationContext.errors[0]"
              :state="getValidationState(validationContext)"
            >
              <gl-form-input
                id="first_name"
                v-model.trim="form.firstName"
                name="customer[first_name]"
                :state="getValidationState(validationContext)"
              />
            </gl-form-group>
          </validation-provider>
        </div>
        <div class="col-md-6 col-12 pl-md-0">
          <validation-provider
            #default="validationContext"
            name="Last name"
            rules="required|noEmoji"
            data-testid="last-name"
            slim
          >
            <gl-form-group
              label="Last name"
              label-for="last_name"
              :invalid-feedback="validationContext.errors[0]"
              :state="getValidationState(validationContext)"
            >
              <gl-form-input
                id="last_name"
                v-model.trim="form.lastName"
                name="customer[last_name]"
                :state="getValidationState(validationContext)"
              />
            </gl-form-group>
          </validation-provider>
        </div>
      </div>

      <validation-provider
        #default="validationContext"
        name="Company"
        rules="required|noEmoji"
        data-testid="organization"
        slim
      >
        <gl-form-group
          label="Company / Organization name"
          label-for="company"
          :invalid-feedback="validationContext.errors[0]"
          :state="getValidationState(validationContext)"
        >
          <gl-form-input
            id="company"
            v-model.trim="form.organization"
            name="customer[company]"
            :state="getValidationState(validationContext)"
          />
        </gl-form-group>
      </validation-provider>

      <validation-provider
        #default="validationContext"
        name="Total number of employees"
        rules="required"
        data-testid="employee-count"
        slim
      >
        <gl-form-group
          label="Total number of employees"
          label-for="company_size"
          :invalid-feedback="validationContext.errors[0]"
          :state="getValidationState(validationContext)"
        >
          <gl-form-select
            id="company_size"
            v-model="form.employeeCount"
            :options="$options.EMPLOYEES_COUNT"
            name="customer[company_size]"
            :state="getValidationState(validationContext)"
          />
        </gl-form-group>
      </validation-provider>

      <validation-provider
        #default="validationContext"
        name="Address 1"
        rules="noEmoji"
        data-testid="address-one"
        slim
      >
        <gl-form-group
          label="Address 1 (optional)"
          label-for="address_1"
          :invalid-feedback="validationContext.errors[0]"
          :state="getValidationState(validationContext)"
        >
          <gl-form-input
            id="address_1"
            v-model.trim="form.address1"
            name="customer[address_1]"
            :state="getValidationState(validationContext)"
          />
        </gl-form-group>
      </validation-provider>

      <validation-provider
        #default="validationContext"
        name="Address 2"
        rules="noEmoji"
        data-testid="address-two"
        slim
      >
        <gl-form-group
          label="Address 2 (optional)"
          label-for="address_2"
          :invalid-feedback="validationContext.errors[0]"
          :state="getValidationState(validationContext)"
        >
          <gl-form-input
            id="address_2"
            v-model.trim="form.address2"
            name="customer[address_2]"
            :state="getValidationState(validationContext)"
          />
        </gl-form-group>
      </validation-provider>

      <div class="row">
        <div class="col-md-6 col-12">
          <validation-provider
            #default="validationContext"
            name="City"
            rules="required|noEmoji"
            data-testid="city"
            slim
          >
            <gl-form-group
              label="City"
              label-for="city"
              :invalid-feedback="validationContext.errors[0]"
              :state="getValidationState(validationContext)"
            >
              <gl-form-input
                id="city"
                v-model.trim="form.city"
                name="customer[city]"
                :state="getValidationState(validationContext)"
              />
            </gl-form-group>
          </validation-provider>
        </div>
        <div class="col-md-6 col-12 pl-md-0">
          <validation-provider
            #default="validationContext"
            name="Postal code"
            rules="required|noEmoji"
            data-testid="zip-code"
            slim
          >
            <gl-form-group
              label="Postal code"
              label-for="zip_code"
              :invalid-feedback="validationContext.errors[0]"
              :state="getValidationState(validationContext)"
            >
              <gl-form-input
                id="zip_code"
                v-model.trim="form.zipCode"
                name="customer[zip_code]"
                :state="getValidationState(validationContext)"
              />
            </gl-form-group>
          </validation-provider>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-12">
          <country-select name="customer[country]" @change="countryChanged" />
        </div>
        <div class="col-md-6 col-12 pl-md-0">
          <state-select name="customer[state]" :country="selectedCountry" />
        </div>
      </div>

      <div v-if="vatFieldVisible" class="row">
        <div class="col-md-6 col-12">
          <validation-provider
            #default="validationContext"
            name="Tax ID"
            rules="noEmoji"
            data-testid="vat"
            slim
          >
            <gl-form-group
              label="Tax ID"
              label-for="vat"
              :invalid-feedback="validationContext.errors[0]"
              :state="getValidationState(validationContext)"
            >
              <gl-form-input
                id="vat"
                v-model="form.vatCode"
                name="customer[vat_code]"
                :show="vatFieldVisible"
                :state="getValidationState(validationContext)"
              />
            </gl-form-group>
          </validation-provider>
        </div>
      </div>

      <gl-button class="gl-mt-6" type="submit" block variant="confirm">Continue</gl-button>
    </form>
  </validation-observer>
</template>
