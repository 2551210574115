import * as Sentry from '@sentry/browser';

/**
 * Returns a parsed JSON object when given a valid JSON string.
 * Otherwise, it returns the `fallbackValue` and logs the error to Sentry.
 *
 * @param  {*} data Any data to attempt to parse as JSON.
 * @param  {*} fallbackValue Any data to return in case the parsing fails
 * @returns {Object|*}
 */
export default function tryJsonParse(data, fallbackValue) {
  try {
    return JSON.parse(data);
  } catch (error) {
    Sentry.captureException(error);

    return fallbackValue;
  }
}
