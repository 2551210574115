<script>
import { GlFormSelect, GlFormGroup } from '@gitlab/ui';
import { ValidationProvider } from 'vee-validate';
import * as Sentry from '@sentry/browser';
import { COUNTRIES_WITH_STATES_REQUIRED } from '~/javascripts/constants';
import getStateListQuery from '~/graphql/queries/get_state_list.query.graphql';
import validationStateMixin from '~/javascripts/forms/validation/mixins/validation_state_mixin';

export default {
  name: 'StateSelect',
  components: {
    GlFormGroup,
    GlFormSelect,
    ValidationProvider,
  },
  mixins: [validationStateMixin],
  props: {
    country: {
      type: String,
      required: true,
    },
    initialState: {
      type: String,
      required: false,
      default: '',
    },
    cssClasses: {
      type: Array,
      required: false,
      default: () => [],
    },
    searchByName: {
      type: Boolean,
      required: false,
      default: false,
    },
    useNameForValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: 'State',
    },
  },
  emits: ['change', 'ready'],
  data() {
    return {
      selectedState: this.initialState,
      states: [],
    };
  },
  computed: {
    disabled() {
      return Boolean(this.$apollo.queries.states.loading);
    },
    isStateRequired() {
      return COUNTRIES_WITH_STATES_REQUIRED.includes(this.country);
    },
    validationRules() {
      return this.isStateRequired ? 'required' : '';
    },
    stateOptions() {
      if (!this.useNameForValue) {
        return this.states;
      }

      return this.states.map((state) => ({
        text: state.text,
        value: state.text,
      }));
    },
  },
  watch: {
    country() {
      this.selectedState = '';
    },
  },
  methods: {
    setState(state) {
      this.selectedState = state;
      this.$emit('change', this.selectedState);
    },
  },
  apollo: {
    states: {
      query: getStateListQuery,
      loadingKey: 'loading',
      notifyOnNetworkStatusChange: true,
      variables() {
        return {
          country: this.country,
          searchByName: this.searchByName,
        };
      },
      skip() {
        return !this.country;
      },
      result(response) {
        this.$emit('ready', !response.loading);
      },
      error(error) {
        Sentry.captureException(error);
      },
    },
  },
};
</script>

<template>
  <validation-provider #default="validationContext" name="State" :rules="validationRules" slim>
    <gl-form-group
      :class="cssClasses"
      :label="label"
      label-for="state"
      :invalid-feedback="validationContext.errors[0]"
      :state="getValidationState(validationContext)"
    >
      <gl-form-select
        id="state"
        v-model="selectedState"
        :options="stateOptions"
        :disabled="disabled"
        v-bind="$attrs"
        :state="getValidationState(validationContext)"
        @change="setState"
      >
        <template #first>
          <option value="" disabled selected>Select state</option>
        </template>
      </gl-form-select>
    </gl-form-group>
  </validation-provider>
</template>
