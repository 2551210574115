import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { apolloClient } from '~/javascripts/lib/graphql';
import tryJSONParse from '~/javascripts/lib/try_json_parse';
import SignUpApp from './components/sign_up_app.vue';

Vue.use(VueApollo);

export const init = (containerSelector = '#js-signin-register-page') => {
  const containerElement = document.querySelector(containerSelector);

  if (!containerElement) {
    return false;
  }

  const accountInfo = tryJSONParse(containerElement.dataset.accountInfo, {});
  const { countriesToHideVatId } = containerElement.dataset;
  const apolloProvider = new VueApollo({ defaultClient: apolloClient });

  return new Vue({
    el: containerElement,
    provide: {
      // Should be refactored to using `tax`-names instead of `vat`:
      // https://gitlab.com/gitlab-org/customers-gitlab-com/-/issues/7469
      countriesToHideVatId,
    },
    render(createElement) {
      return createElement(SignUpApp, {
        props: {
          ...this.$options.el.dataset,
          accountInfo,
        },
      });
    },
    apolloProvider,
  });
};
